<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('sales_commission.sales_commission') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('sales_commission.commission_name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.name[0] }}
                                </span>
              </div>

            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('sales_commission.employee') }}</label>
                <div class="input-group mb-3">
                  <multiselect v-model="employee"
                               :placeholder="$t('sales_commission.employee')"
                               :class="validation && validation.employee_ids ? 'is-invalid' : ''"
                               label="full_name"
                               track-by="id"
                               :options="employee_list"
                               :multiple="true"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getEmployees($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/employees/employees/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('sales_commission.employee') }}.</span>

                <span v-if="validation && validation.employee_ids" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.employee_ids[0] }}
                                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_commission.commission_period') }}</label>
                <select name="" id="commission_period" v-model="data.commission_period" class="custom-select" :class="validation && validation.commission_period ? 'is-invalid' : ''">
                  <option v-for="row in commission_period_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.commission_period" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.commission_period[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_commission.commission_calculation') }}</label>
                <select name="" id="commission_calculation" v-model="data.commission_calculation" class="custom-select" :class="validation && validation.commission_calculation ? 'is-invalid' : ''">
                  <option v-for="row in commission_calculation_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.commission_calculation" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.commission_calculation[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_commission.currency') }}</label>
                <div class="input-group mb-3">
                  <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.currency_id[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('exchange_rate') }}</label>
                <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.exchange_rate[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_commission.target_type') }}</label>
                <b-form-group label="" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="data.target_type" :aria-describedby="ariaDescribedby" name="target_type-radios" value="1">{{ $t('sales_commission.target_revenue') }}</b-form-radio>
                  <b-form-radio v-model="data.target_type" :aria-describedby="ariaDescribedby" name="target_type-radios" value="2">{{ $t('sales_commission.target_volume') }}</b-form-radio>
                </b-form-group>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_commission.target_value') }}</label>
                <input type="number" v-model="data.target_value" class="form-control" :class="validation && validation.target_value ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.target_value" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.target_value[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('sales_commission.applied_to_the_following_items') }}</h6>
            <button type="button" class="btn btn-primary" @click="addItemRowToList">{{ $t('add_more') }}</button>
          </div>
          <div class="bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList(0)">
              <thead>
              <tr>
                <th width="20%">{{ $t('sales_commission.type') }}</th>
                <th>{{ $t('sales_commission.type_name') }}</th>
                <th width="10%">{{ $t('sales_commission.commission_percent') }}</th>
                <th width="10%"></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in items_list" :key="index">
                <td>
                  <select @input="repeaterType(index, $event)" class="custom-select">
                    <option v-for="list in commission_applied_item_type_list" :value="list.id" :selected="list.id == null" :disabled="list.id == null" :key="list.id">{{ list.title }}</option>
                  </select>
                </td>
                <td>
                  <div class="d-flex search-item-group input-group" v-if="row.commission_applied_item_type == 3" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_commission.item')">
                    <select name="" id="" v-model="row.f_by" class="custom-select">
                      <option value="name" selected>{{ $t('items.name') }}</option>
                      <option value="sku_code">{{ $t('items.sku_code') }}</option>
                    </select>
                    <multiselect v-model="row.type_obj" style="width: 60%;"
                                 :placeholder="$t('sales_commission.item')"
                                 :label="row.f_by? row.f_by:'name'"
                                 track-by="id"
                                 :options="items"
                                 :multiple="true"
                                 @input="selectItem(index)"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 :internal-search="false"
                                 @search-change="getItems(row.f_by, $event)">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="javascript:;" @click="showItemModal(index)"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>

                  <div class="input-group mb-3" v-if="row.commission_applied_item_type == 2">
                    <treeselect
                        :options="categories"
                        :load-options="loadOptions"
                        :multiple="true"
                        :value="row.type_ids"
                        @input="updateValue(index, $event)"
                        :searchable="true"
                        :show-count="true"
                        :no-children-text="$t('No_sub_options')"
                        :no-options-text="$t('No_options_available')"
                        :no-results-text="$t('No_results_found')"
                        :placeholder="$t('Select')">
                    </treeselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/categories" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                </td>
                <td><input v-model="row.commission_percentage" type="number" class="form-control"></td>
                <td>
                  <v-icon style="color: #dc3545;" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>

            </table>

          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('sales_commission.notes') }}</label>
                  <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.notes[0] }}
                                </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>


      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
<!--            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>-->
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import ItemForm from "@/view/content/forms/ItemForm";

export default {
  name: "form-sales-commission",
  components: {'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'salestargetcommission/sales-commissions',
      mainRouteDependency: 'base/dependency',
      data: {

        name: null,
        employee_ids: null,
        commission_calculation: 0,
        commission_period: 0,
        currency_id: null,
        target_type: 0,
        target_value: null,
        status: true,
        notes: null,
        exchange_rate: null,
      },
      employee: [],
      idEditing: this.$route.params.id,
      isEditing: false,

      commission_period_list: [],
      commission_calculation_list: [],
      commission_applied_item_type_list: [
        {id: null, title: this.$t('all')},
        {id: 2, title: this.$t('sales_commission.category')},
        {id: 3, title: this.$t('sales_commission.item')},
      ],
      target_type_list: [],
      currencies: [],
      employee_list: [],
      items: [],
      categories: [],

      validation: null,


      items_list_form: {id: null, commission_applied_item_type: null, type_ids: null, type_obj: null, is_all_product: null, commission_percentage: null,},
      items_list: [],

      inner_index: null,

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    employee: function (val) {
      this.data.employee_ids = [];
      if (val) {
        val.map((row) => {
          this.data.employee_ids.push(row.id);
        });
      }
    }
  },
  methods: {

    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        items_list: this.items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'sales-commissions.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: this.items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'sales-commissions.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.employee_ids = response.data.data.employee_ids;
        this.data.commission_calculation = response.data.data.commission_calculation;
        this.data.commission_period = response.data.data.commission_period;
        this.data.currency_id = response.data.data.currency_id;
        this.data.target_type = response.data.data.target_type;
        this.data.target_value = response.data.data.target_value;
        this.data.status = response.data.data.status;
        this.data.notes = response.data.data.notes;
        this.data.exchange_rate = response.data.data.exchange_rate;
        this.employee = response.data.data.employees;
        this.items_list = response.data.data.items_list;

        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
      });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getEmployees(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/employees", {params: {name: filter}}).then((response) => {
          this.employee_list = response.data.data;
        });
    },
    getCommissionCalculation() {
      ApiService.get(this.mainRouteDependency + "/commission_calculation").then((response) => {
        this.commission_calculation_list = response.data.data;
      });
    },
    getCommissionPeriod() {
      ApiService.get(this.mainRouteDependency + "/commission_period").then((response) => {
        this.commission_period_list = response.data.data;
      });
    },
    getTargetType() {
      ApiService.get(this.mainRouteDependency + "/target_type").then((response) => {
        this.target_type_list = response.data.data;
      });
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },

    loadOptions() {
    },


    addItemRowToList() {
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, commission_applied_item_type: null, type_ids: null, type_obj: null, is_all_product: null, commission_percentage: null,};
    },
    removeItemRowFromList(index) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
    repeaterType(index, event) {
      this.items_list[index].commission_applied_item_type = event.target.value;
      this.items_list[index].type_ids = [];
      this.items_list[index].type_obj = [];
    },
    selectItem(index) {
      let _ids = [];
      this.items_list[index].type_obj.map((row) => {
        _ids.push(row.id);
      });
      this.items_list[index].type_ids = _ids;
    },
    updateValue(index, value) {
      this.items_list[index].type_ids = value
    },
    showItemModal(index) {
      this.inner_index = index;
      this.$refs['modalItem'].show();
    },
    hideItemModal() {
      this.inner_index = null;
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewItem(object) {
      if (object) {
        this.items_list[this.inner_index].type_obj.push(object);
        this.selectItem(this.inner_index);
      }
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
      });
    }

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_commissions"), route: '/salestargetcommission/sales-commissions'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    this.getCurrencies();
    // this.getItems();
    // this.getEmployees();
    this.getCommissionCalculation();
    this.getCommissionPeriod();
    this.getTargetType();
    this.getCategories();

    if (this.idEditing) {
      this.getData();
    } else {
      this.defaultDataForUser();
      if (this.items_list.length <= 0) {
        this.addItemRowToList();
      }
    }

  },
};
</script>